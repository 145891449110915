import { Dialog, Toast, Form } from 'vant'
import { mapState } from 'vuex'
import { descriptor } from './descriptor'
import UploadIdentification from '@/components/upload-identification/index.vue'
import CellSinglePicker from '@/components/common/cell-single-picker/index.vue'
import * as authServicer from '@/service/auth'
import * as otherServicer from '@/service/other'
import { verifyIdentityCard } from '@/utils/verify.js'
/**
 * 合同-签名
 */
export default {
  name: 'contract-sign',
  components: {
    UploadIdentification,
    CellSinglePicker,
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },

  data() {
    return {
      showMask: false,
      qiniu: {},//七牛云配置信息
      identityTypes: [],//证件类型列表
      params: {
        back: null,//反面照
        font: null,//正面照
        identityBackMediaId: null,//身份证反面照
        identityFrontMediaId: null,//身份证正面照
        name: '',//用户的姓名
        identityType: null,//证件类型name
        idNum: null,//证件号码
        signature: null,//签名照片
      },
      labelMap: {},
      //  身份证信息

    }
  },

  methods: {
    // 提交
    async onSubmit() {
      this.showMask = true;
      //将front的数据放进去
      if (!this.validateForm()) {
        this.showMask = false;
        return;
      }
      // 添加额外判定，如果类型是身份证，则校验是否15位或则18位
      if (!this.extraverify()) {
        this.showMask = false;
        return;
      }
      // 如果签名已经上传过了。则不用再重新去上传
      if (this.params.signature && this.params.signature.indexOf(this.qiniu.downloadPrefix) === 0) {
      }
      else {
        // 将图片上传到七牛云上
        const { data } = await otherServicer.sendBase64ToQiniu(this.qiniu.uploadPrefix, this.params.signature, this.qiniu.qiniutoken);
        this.params.signature = `${this.qiniu.downloadPrefix}/${data.key}`;
      }
      try {
        await otherServicer.contractSignContract({
          userId: this.user.id,
          systemId: JSON.parse(sessionStorage.getItem("signsystemIds"))[0],
          name: this.params.name,
          identityType: this.params.identityType.identityTypeCode,
          identityCode: this.params.idNum,
          identityFrontUrl: this.params.font.serverId,
          identityBackUrl: this.params.back.serverId,
          signatureUrl: this.params.signature
        });
        this.showMask = false;
        // 默认为签约成功
        this.$router.push({
          name: 'contract-result',
          query: {
            name: this.params.name,
          }
        });
      } catch (error) {
        this.showMask = false;
        console.log(error);
      }

    },
    // 清除证件号码
    clearIdNum() {
      this.params.idNum = null;
    },
    // 清除签名
    clearsign() {
      this.params.signature = null;
      sessionStorage.removeItem("signature");
    },

    // 添加的一些额外判断
    extraverify() {
      //TODO..... 错误处理，不应该由前端判定，但是时间不足，改成前端，出问题了需要跟后台对下是否身份证的code更改了
      if (this.params.identityType && this.params.identityType.identityTypeCode === 0 && this.params.idNum) {
        if (!verifyIdentityCard(this.params.idNum)) {
          return false;
        }
        return true
      }
      return true;
    },
    /**
     * 校验表单
     * 校验通过：返回true，否则返回false
     */
    validateForm() {
      let pass = true
      // 遍历
      Object.getOwnPropertyNames(this.params).forEach((e) => {
        const validator = descriptor[e];
        if (validator) {
          //  不能为空白
          if (validator.required) {
            // 简单判断不为null，undefined，长度为0
            if (!this.params[e] || this.params[e].length == 0) {
              Toast(descriptor[e].message);
              pass = false;
              return;
            }
            // 如果有校验规则
            if (validator.validator && validator.validator(this.params[e]).length != 0) {
              Toast(descriptor[e].message);
              pass = false;
              return;

            }
          }

        }
      });

      return pass
    },

    //根据实际情况增加对某些字段的校验
    adddescriptor() {
    },
    // 跳转到签名页面
    toSignature() {
      this.$router.push({
        name: 'electronic-sign'
        // name:'test'
      });

    },
    // 获取数据-身份证类型
    async getDataSource() {
      const
        identityTypes
          = await
            authServicer.identityTypes();
      this.identityTypes = identityTypes;
    },
    //获取七牛云的配置信息
    async getConfig() {
      const [{ downloadPrefix, region }, qiniutoken] = await Promise.all([otherServicer.getQiniuConfig(), otherServicer.getToken()]);
      this.qiniu = { downloadPrefix, region, qiniutoken }
    }
  },

  // 
  activated() {
    const signsource = sessionStorage.getItem("signature");
    signsource ? this.params.signature = signsource : null;
  },
  mounted() {
    // 获取签名
    this.getDataSource();
    this.adddescriptor();
    this.getConfig();
  }
}

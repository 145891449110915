// 签约校验规则
export const descriptor = {
  name: {
    required: true,
    message: '客户姓名不能为空'
  },
  identityType: {
    required: true,
    message: '证件类型不能为空',
  },
  idNum: {
    required: true,
    message: '证件号码不能为空',
  },
  signature: {
    required: true,
    message: '签名不能为空'
  },
  back: {
    required: true,
    message: '证件反面照不能为空'
  }
  ,
  font: {
    required: true,
    message: '证件正面照不能为空'
  }




}

<template>
  <div class="signatureBox">
    <div class="canvasBox" ref="canvasHW">
      <canvas @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd" ref="canvasF"></canvas>
      <div class="operation">
        <button id="clearCanvas" @click="overwrite">重新签名</button>
        <button @click="savewrite()" id="saveCanvas">确定保存</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "signature",
  data() {
    return {
      points: [],
      canvasTxt: null,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      endY: 0,
      endX: 0,
      w: null,
      h: null,
      isDown: false
    };
  },
  created() {},
  mounted() {
    let canvas = this.$refs.canvasF;
    canvas.height = this.$refs.canvasHW.offsetHeight;
    canvas.width = this.$refs.canvasHW.offsetWidth;
    this.canvasTxt = canvas.getContext("2d");
  },
  components: {},
  methods: {
    touchStart(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX,
          y: ev.targetTouches[0].clientY
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.lineWidth = 3;
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },

    touchMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX,
          y: ev.targetTouches[0].clientY
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },

    touchEnd(ev) {
      ev = ev || event;
      ev.preventDefault();
      console.log(ev);
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX,
          y: ev.targetTouches[0].clientY
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.points.push({ x: -1, y: -1 });
      }
    },
    //重写
    overwrite() {
      this.canvasTxt.clearRect(
        0,
        0,
        this.$refs.canvasF.width,
        this.$refs.canvasF.height
      );
      this.points = [];
    },
    // 保存笔记到sessionstorage中，并且返回到页面
    savewrite() {
      let imgBase64 = this.$refs.canvasF.toDataURL();
      sessionStorage.setItem("signature", imgBase64);
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.signatureBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.visaDetailTop {
  /*position: absolute;*/
  /*top: 0px;*/
  /*left: 0px;*/
  width: 100%;
  /*overflow: hidden;*/
  padding: 5px;
  box-sizing: border-box;
  z-index: 2;
  border-bottom: 1px solid #e5e5e5;
}
.visaDetailTop p {
  margin: 0px;
  text-align: center;
  color: #000;
  font-size: 1em;
  position: relative;
}
p.visaTitle {
  width: 100%;
  position: absolute;
  top: 5px;
  left: 0px;
  text-align: center;
  font-size: 1.2em;
}
.btnBack {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  border-color: transparent;
  outline: none;
}
.btnDaoHang {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 2.2em;
  width: 2em;
  z-index: 1;
  background: transparent;
  border-color: transparent;
  outline: none;
}
.visaDetailTop p span {
  color: #fff;
  font-size: 1.2em;
}
.visaDetailTop p:first-of-type {
  float: left;
}
.visaDetailTop p:nth-of-type(2) {
  float: right;
}
.canvasBox {
  box-sizing: border-box;
  flex: 1;
}
.btnBox {
  height: 30px;
  padding: 5px;
  text-align: right;
  line-height: 30px;
}
.btnBox button:first-of-type {
  border: 1px solid #71b900;
  background: transparent;
  border-radius: 4px;
  padding: 5px 10px;
}
.btnBox button:last-of-type {
  border: 1px solid #71b900;
  background: #71b900;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
}
@media only screen and (min-width: 750px) {
  .signatureBox {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow: visible;
  }
}
.operation {
  position: fixed;
  bottom: 10px;
  width: 100%;

  display: flex;
  justify-content: space-around;
}

#clearCanvas {
  font-size: 17px;
  height: 40px;
  width: 120px;
  line-height: 40px;
  border: none;
}

#saveCanvas {
  font-size: 17px;
  border: none;
  height: 40px;
  width: 120px;
}
</style>
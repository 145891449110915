import { Toast } from "vant";

// 校验15位数字，18位数字的身份证号码,只是做数字的校验
export function verifyIdentityCard(cardnum) {
  if (checkIdentityCode(cardnum)) {
    var date = cardnum.substring(6, 14);
    if (checkIdentityDate(date)) {
      if (checkIdentityProvince(cardnum.substring(0, 2))) {
        return true;
      }
    }
  }
  Toast("身份证号码输入不正确")
  return false;
}

// 校验手机号码
export function verifyTel(tel, msg = '请输入正确的手机号码'){
  var pattern = /^1[23456789]\d{9}$/;
  if (pattern.test(tel)) return true;
  else {
    Toast(msg)
    return false;
  }
}

// 校验航班号
export function verifyFlightCode(code, msg = '请输入正确的航班号'){
  var pattern = /^[A-Z\d]{2}\d{3,4}$/;
  if (pattern.test(code)) return true;
  else {
    Toast(msg)
    return false;
  }
}

// 检查身份证后四位,后四位生成公式可以百度
function checkIdentityCode(cardnum) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  var code = cardnum.substring(17);
  if (p.test(cardnum)) {
    var sum = 0;
    for (var i = 0; i < 17; i++) {
      sum += cardnum[i] * factor[i];
    }
    if (parity[sum % 11] == code.toUpperCase()) {
      return true;
    }
  }
  return false;
}
// 验证年月日,只是单纯的校验日期
let checkIdentityDate = function (cardnum) {
  var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
  if (pattern.test(cardnum)) {
    var year = cardnum.substring(0, 4);
    var month = cardnum.substring(4, 6);
    var date = cardnum.substring(6, 8);
    var date2 = new Date(year + "-" + month + "-" + date);
    if (date2 && date2.getMonth() == (parseInt(month) - 1)) {
      return true;
    }
  }
  return false;
}
// 验证地区，就枚举认证下而已
function checkIdentityProvince(cardnum) {
  let pattern = /^[1-9][0-9]/;
  let provs = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门"
  };
  if (pattern.test(cardnum)) {
    if (provs[cardnum]) {
      return true;
    }
  }
  return false;
}
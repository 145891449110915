export default {
  name: 'contract-guide',
  data(){
    return {
      content:''//合同的内容
    }
  },
  mounted(){
    this.content=sessionStorage.getItem("signContent");
  },
  methods: {
    // 跳转到签约页面
    toContract() {
      this.$router.push({
        name: 'contract-sign'
      })
    }
  }
}
/**
 * 签约合同成功
 */
export default {
  name: 'contract-result',
  data() {
    return {
      name: '',//签约人的名字
    }
  },
  methods: {
    // 确定按钮，返回我的团体页面
    sure() {
      this.$router.replace({
        name: 'product-list'
      })
    },

  },

  created() {
    const { name } = this.$route.query;
    this.name = name;
  }
}